import { frameResolver } from './frameResolver';
import { translationResolver } from './translationResolver';
import { Frame } from '~/lib';
import { NodeStorageKey, SafeNodeStorage, StorageType } from '~/services/storage';
import { Translations } from '~/shared/utils/translation';

export const getBaseContent = async (storage: SafeNodeStorage, rootPath = '/') => {
    console.time('initialContent');

    const frameRequest = storage.getIfAvailable<Frame>(NodeStorageKey.FRAME, {
        fallback: () => frameResolver({ url: rootPath }).then((response) => response.data),
    });

    const translationsRequest = storage.getIfAvailable(NodeStorageKey.TRANSLATIONS, {
        /**
         * Translations don't need to be revalidated as often so, we only have
         * to check every hour (60 minutes) for fresh content.
         **/
        lifespanInMinutes: 60,
        fallback: () => translationResolver({ url: rootPath }).then((response) => response.data),
    });

    /**
     * Run request for Frame and Translations data in parallel to save bandwidth
     * as these are independent from each other.
     */
    const [frame, translations] = await Promise.all([frameRequest, translationsRequest]);

    console.timeEnd('initialContent');

    return { frame, translations };
};

type GetInitialLoadExtraContentProps = {
    url: string;
};

/**
 * @deprecated Prefer to use `getBaseContent()` instead.
 */
export const getInitialLoadExtraContent = async (
    storage: StorageType,
    _initialLoad: boolean,
    props: GetInitialLoadExtraContentProps,
) => {
    let frame = JSON.parse(storage.get('frame')) as Frame;
    let translations = JSON.parse(storage.get('translations')) as Translations;

    const lastUpdated = storage.get('updated');
    const lastUpdatedDate = lastUpdated ? new Date(JSON.parse(lastUpdated)) : undefined;
    const needsUpdate = lastUpdatedDate
        ? (new Date().getTime() - lastUpdatedDate.getTime()) / (1000 * 60) > 5
        : true;

    if (needsUpdate || !lastUpdated) {
        const frameRequest = frameResolver(props);
        const translationRequest = translationResolver(props);

        console.time('frameResolver');
        const { data: frameData } = await frameRequest;
        frame = frameData;
        storage.set('frame', JSON.stringify(frame));
        console.timeEnd('frameResolver');

        console.time('translationResolver');
        const { data: translationsData } = await translationRequest;
        storage.set('translations', JSON.stringify(translations));
        translations = translationsData;
        console.timeEnd('translationResolver');

        storage.set('updated', JSON.stringify(new Date()));
    } else {
        //backup case in case somehow the frame/translation from storage is null
        if (!frame) {
            const { data: newFrameData } = await frameResolver(props);
            storage.set('frame', JSON.stringify(frame));
            frame = newFrameData;
        }
        if (!translations) {
            const { data: translationsData } = await translationResolver(props);
            storage.set('translations', JSON.stringify(translations));
            translations = translationsData;
        }
    }

    return {
        frame,
        translations,
    };
};
