import type { P10SearchPage, P40ProductListPage } from '~/lib';
import { renderToString } from 'react-dom/server';
import { generateAlgoliaConfigProps } from '~/features/productList/Algoliga.utils';
import type { ParsedUrlQuery } from 'querystring';
import {
    InstantSearch,
    Configure,
    Index,
    getServerState,
    ConfigureProps,
} from 'react-instantsearch';
import { MainAlgoliaIndex, algoliaClient, pathToSearchState } from '~/lib/algolia';
import { IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { routing as generateRouting } from '~/features/productList/routing';
import { VirtualPagination, VirtualSearch } from '~/features/productList/components/VirtualWidgets';

type Props = {
    req: IncomingMessage & {
        cookies: NextApiRequestCookies;
    };
    page: P40ProductListPage | P10SearchPage;
    query: ParsedUrlQuery;
};

type FacetValue = string | string[];

// TODO(IMC-514): Remove "any" types used.
const generateFacetFilters = (searchState: any) => {
    const { refinementList, toggle } = searchState;
    const filters = [refinementList, toggle].filter(Boolean);

    if (!filters.length) return null;

    const f = filters.map((item: Record<string, FacetValue>) => {
        return Object.entries(item).reduce((acc, [key, values]) => {
            if (Array.isArray(values)) {
                acc.push(values.map((value: string) => `${key}:${value}`));
            } else if (values.length >= 2) {
                acc.push(`${key}:${values}`);
            }

            return acc;
        }, [] as FacetValue[]);
    });

    return f.flat();
};

export const plpDataResolver = async ({ req, page, query }: Props) => {
    const { productids, productIds, campaign, query: searchQuery } = query;

    const searchState = pathToSearchState(req.url);
    const facetFilters = generateFacetFilters(searchState) ?? [];

    // Extract page number from search state
    const pageParam = searchState.page;
    const pageValue = Array.isArray(pageParam) ? pageParam[0] : pageParam;

    const pageNumber =
        typeof pageValue !== 'string' && typeof pageValue !== 'number'
            ? 0
            : Math.max(0, Number(pageValue) - 1);

    let algoliaProps: ConfigureProps & { query?: string };

    if (page.type === 'p40ProductListPage') {
        const { dataConfiguration, dataType, parentDataConfiguration, parentDataType } = page;

        algoliaProps = generateAlgoliaConfigProps({
            filter: dataConfiguration,
            filterType: dataType,
            parentFilter: parentDataConfiguration,
            parentFilterType: parentDataType,
            productIds: productids ?? productIds,
            campaign,
        });
    } else {
        algoliaProps = {
            ...generateAlgoliaConfigProps({
                filter: 'search',
                filterType: 'Context Rule Name',
            }),
            query: Array.isArray(searchQuery) ? searchQuery[0] : searchQuery,
        };
    }

    const protocol = req.headers.referer?.split('://')[0] ?? 'https';
    const serverUrl = `${protocol}://${req.headers.host}${req.url}`;

    const serverState = await getServerState(
        <InstantSearch
            indexName={MainAlgoliaIndex}
            searchClient={algoliaClient}
            routing={generateRouting(serverUrl)}
            future={{ preserveSharedStateOnUnmount: true }}
        >
            {page.type === 'p10SearchPage' && <VirtualSearch />}

            <Index indexName={MainAlgoliaIndex} indexId="products">
                <VirtualPagination />

                <Configure
                    {...algoliaProps}
                    hitsPerPage={20}
                    page={pageNumber}
                    clickAnalytics
                    facetFilters={facetFilters}
                    getRankingInfo
                />
            </Index>

            <Index indexName={MainAlgoliaIndex} indexId="facets">
                <Configure
                    facets={['*']}
                    ruleContexts={algoliaProps.ruleContexts}
                    hitsPerPage={0}
                />
            </Index>
        </InstantSearch>,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { renderToString },
    );

    return { serverState, serverUrl };
};
