import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { IncomingMessage } from 'http';
import cookie, { CookieSerializeOptions } from 'cookie';
import { pageResolver } from '../../utils';
import { getPDPParams, getPDPUrl, getProducts } from '~/features/product-details-page/pdp-api';
import { PDP_SSR_Data, SFProduct } from '~/features/product-details-page/pdp.definitions';
import { brandsResolver } from '../../utils/pageResolver';
import { getServersideApi } from '~/features/commerce-api';
import { plpDataResolver } from '~/services/plp/plpDataResolver';
import { getStoreList } from '~/features/find-store/components/store-api';
import { Store_SSR_Data } from '~/features/find-store/store.definitions';
import { getQueryValue } from '~/shared/utils/get-query-value';
import { hasUppercaseOnPath, lowercasePathOfUrl } from '~/utils/url';
import { isDeadProduct } from '~/shared/utils/is-dead-product';
import { Product } from '~/lib/data-contract/salesforce';
import { extractMetaImage } from '~/features/product-details-page/pdp-utils';
import { DynamicPageProps } from './DynamicPage';
import { getBaseContent } from '../../utils/getInitialLoadExtraContent';
import { SafeNodeStorage } from '~/services/storage';

const isRequestFromMobile = (request: IncomingMessage) => {
    const userAgent = request.headers['user-agent'];

    if (!userAgent) return false;

    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

/**
 * Fetch the page and the frame and return as props
 *
 * CacheControl is set based on the response header from the page request
 */
export const getDynamicPageProps = async (
    context: GetServerSidePropsContext,
    storage: SafeNodeStorage,
) => {
    console.time('page-load');

    const headersFromCMS = ['cache-control'];
    const headersToCMS = ['accept-language', 'cf-ipcountry', 'x-forwarded-for', 'user-agent'];

    const { query, req, res, resolvedUrl } = context;
    const api = getServersideApi(storage);

    /**
     * REDIRECT: We will redirect the user to a lowercased path in case their URL's path has an uppercase character.
     * Other parts of the URL will be kept untouched.
     *
     * Ex.: https://imerco.dk/Path-to-Page?Query=1 -> https://imerco.dk/path-to-page?Query=1
     */
    if (hasUppercaseOnPath(resolvedUrl)) {
        return {
            redirect: {
                permanent: true,
                destination: lowercasePathOfUrl(resolvedUrl),
            },
        };
    }

    const propData: PDP_SSR_Data & Store_SSR_Data = {
        product: null,
        variants: null,
        brands: null,
        plpResult: null,
        storeList: null,
        store: null,
    };

    const { frame, translations } = await getBaseContent(storage);

    /**
     * NOTE: Our app requires Frame data to completely function. If we are unavailable to retrieve it,
     * something may have gone wrong server-side.
     */
    if (!frame) {
        res.statusCode = 500;
        throw new Error('Missing Frame data from Umbraco.');
    }

    const urlAsProductId = resolvedUrl && parseInt(resolvedUrl.split('/')[1], 10);

    let pageUrl = resolvedUrl;

    if (query.id || urlAsProductId) {
        const id = query.id?.toString() || urlAsProductId.toString();

        const { data: productData } = await getProducts(api, {
            ids: id,
            currency: frame?.market.currency?.currency,
            allImages: true,
        });

        if (productData) {
            if (productData[0].c_pageURL) {
                let productUrl = decodeURIComponent(productData[0].c_pageURL).toLowerCase();
                if (!productUrl.startsWith('/')) {
                    productUrl = `/${productUrl}`;
                }
                const cleanResolved = `/${query.slug}?id=${query.id}`;
                const cleanResolvedFromSlugIssue = `/${query.slug
                    ?.toString()
                    .replace(',', '/')}?id=${query.id}`; //fix for azure changing "/" in the url to ","

                if (!(productUrl === cleanResolved || productUrl === cleanResolvedFromSlugIssue)) {
                    return {
                        redirect: {
                            destination: `${productData[0].c_pageURL.toLowerCase()}`,
                            permanent: true,
                        },
                    };
                }
            }
            propData.product = productData[0];
            pageUrl = getPDPUrl(frame, resolvedUrl);
        } else {
            return { redirect: { destination: '/', permanent: true } };
        }
    }

    const storesUrl = frame?.settings?.pageReferences.storesPage?.url;
    if (storesUrl && Array.isArray(query.slug) && query.slug[0] === storesUrl.slice(1)) {
        try {
            const storeList = await getStoreList(api);
            propData.storeList = storeList;
        } catch {
            return { redirect: { destination: '/', permanent: false } };
        }
    }

    const brandsOverviewPageUrl = frame?.settings?.pageReferences.brandsOverviewPage?.url;
    if (brandsOverviewPageUrl && resolvedUrl.split('?')[0] === brandsOverviewPageUrl) {
        try {
            const brands = await brandsResolver({
                headers: req.headers,
                forwardHeaders: headersToCMS,
            });
            propData.brands = brands.data;
        } catch (e) {
            return { redirect: { destination: '/', permanent: true } };
        }
    }
    const { data: page, headers } = await pageResolver({
        url: pageUrl,
        headers: req.headers,
        params: propData.product ? getPDPParams(propData.product) : {},
        forwardHeaders: headersToCMS,
    });

    if (!page) {
        throw new Error('Page missing from CMS');
    }

    // Pre-fetch data for product lists on Product List Page and Search Page.
    if (page.type === 'p40ProductListPage' || page.type === 'p10SearchPage') {
        const plpData = await plpDataResolver({ req, page, query });
        if (plpData) propData.plpResult = plpData;
    }

    if (page.type === 'p40ProductListPage') {
        const productTotalPage =
            propData.plpResult?.serverState.initialResults.products?.results.map(
                (item) => item.nbPages,
            );

        const currentPage = getQueryValue(query.page);

        if (productTotalPage && currentPage) {
            const rootPage = pageUrl.replace(/(\?.*)|(#.*)/g, '');

            if (parseInt(currentPage) > productTotalPage[0])
                return { redirect: { destination: rootPage, statusCode: 308 } };
        }
    }

    if (page.type === 'p50ProductDetailsPage' && propData.product) {
        const { c_variants } = propData.product;

        const { brand, category, series, subCategory } = page.productCategoryLinks;
        const categoryFallback = subCategory || series || category || brand;

        // TODO (IMC-514): Remove assertion once propData.product type is fixed.
        if (isDeadProduct(propData.product as Product)) {
            const cookieSettings: CookieSerializeOptions = {
                httpOnly: false,
                sameSite: 'none',
                secure: true,
            };

            if (c_variants?.length > 1) {
                const variantToRedirect = c_variants.find(
                    (item: SFProduct) => item.orderable === true,
                );

                if (variantToRedirect) {
                    const param = resolvedUrl.split('?')[0];

                    res.appendHeader(
                        'Set-Cookie',
                        cookie.serialize('productRedirect', 'alternative', cookieSettings),
                    );
                    return {
                        redirect: {
                            destination: param + '?id=' + variantToRedirect.productId,
                            statusCode: 301,
                        },
                    };
                }
            }

            res.appendHeader(
                'Set-Cookie',
                cookie.serialize(
                    'productRedirect',
                    categoryFallback?.title || 'Category',
                    cookieSettings,
                ),
            );
            return {
                redirect: {
                    destination: categoryFallback?.url ?? '/',
                    statusCode: 301,
                },
            };
        }
    }

    // Map headers from page response.
    // Allows the CMS to handle how pages are cached etc.
    headersFromCMS.forEach((headerKey) => {
        const header = headers.get(headerKey);
        header && res.setHeader(headerKey, header);
    });

    if (page.type === 'redirect') {
        const { destination, permanent } = page;
        return { redirect: { destination, permanent } };
    }

    page.data = propData;
    if (req.headers?.host === 'preprod01.imerco.dk') {
        page.meta = page.meta ?? {};
        page.meta.hideFromRobots = true;
    }

    if (propData.product) {
        const { product } = propData;

        const metaImage = extractMetaImage(product);

        page.meta = page.meta ?? {};
        page.meta.url = product.c_pageURL;
        page.meta.title = product.pageTitle ?? '';
        page.meta.description = product.pageDescription ?? '';
        page.meta.keywords = product.pageKeywords ?? '';
        page.meta.canonical = product.c_canonical
            ? { url: product.c_canonical }
            : product.c_pageURL
              ? { url: product.c_pageURL }
              : undefined;
        page.meta.openGraph = page.meta.openGraph ?? {};
        page.meta.openGraph.title = product.pageTitle ?? '';
        page.meta.openGraph.description = product.pageDescription ?? '';
        page.meta.openGraph.type = 'product';
        page.meta.openGraph.image = metaImage
            ? {
                  id: metaImage.images[0]?.title ?? product.pageTitle,
                  height: 1200,
                  width: 1200,
                  src: metaImage.images[0]?.link,
              }
            : null;

        const brandName = product.brand ? `${product.brand} ` : '';

        page.breadcrumb?.items?.push({
            text: brandName + product.name,
            url: product.c_pageURL,
            title: brandName + product.name,
        });
    } else if (propData.brands) {
        page.type = 'brandsListPage';
    }

    page.isMobile = isRequestFromMobile(req);

    const props: DynamicPageProps = {
        page,
        frame,
        translations: translations ?? null,
    };

    // Check the status code range
    // add statuscode for the 404 page, if within range. this will make google not index this url/slug
    if (Number(props?.page?.statusCode) >= 400 && Number(props?.page?.statusCode) < 500) {
        res.statusCode = Number(props.page.statusCode);
    }

    console.timeEnd('page-load');

    return {
        props,
    } as GetServerSidePropsResult<DynamicPageProps>;
};
